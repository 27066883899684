// Packages
import _ from "lodash";

/**
 * @summary get total campaign views
 * @param {object} campaign
 * @returns {number} total views
 */
function getTotalViews(campaign) {
  const handlesWithStats = campaign.handles.filter((handle) => handle?.statsId?.stats?.length > 0);

  const getStats = (handle) => handle.statsId.stats;

  const stats = _.flatMap(handlesWithStats, getStats);

  const total = stats.reduce((a, b) => a + (b.views || 0), 0);

  return total;
}

/**
 * @summary checks if param is a valid date
 * @param {*} date
 * @returns {boolean} boolean
 */
function isValidDate(date) {
  return date instanceof Date && !Number.isNaN(date);
}

/**
 * @summary Checks whether campaign targets are met
 * @param {object} campaign
 * @returns {{started: boolean, ended: boolean, viewsAchieved: boolean}} values
 */
export default function checkIfTargetsAchieved(campaign) {
  const values = {
    started: true,
    ended: false,
    viewsAchieved: false,
  };

  // Find amount of views & views targets
  const totalViews = getTotalViews(campaign);

  const filteredViews = campaign.targets.filter((targetViews) => targetViews.unit.id === "views");

  // TODO: Fix this linting error
  const maxValue = Math.max.apply(
    Math,
    filteredViews.map((o) => o.value)
  );

  if (maxValue && maxValue > 0 && totalViews > 0 && totalViews >= maxValue) {
    values.viewsAchieved = true;
  }

  // Find date targets
  const currentDate = new Date();

  const filteredTime = campaign.targets.filter((targetTimes) => targetTimes.unit.id === "time");

  const startTimes = filteredTime.map((item) => new Date(item.startTime));

  const endTimes = filteredTime.map((item) => new Date(item.endTime));

  if (!startTimes.length) values.started = true;

  if (!endTimes.length) values.ended = false;

  if (startTimes?.length && endTimes?.length) {
    const earliestDate = new Date(Math.min.apply(null, startTimes));

    const latestDate = new Date(Math.max.apply(null, endTimes));

    if (isValidDate(earliestDate)) {
      values.started = currentDate >= earliestDate;
    }

    if (isValidDate(latestDate)) {
      values.ended = currentDate > latestDate;
    }
  }

  return values;
}
