<template>
  <div class="w-full">
    <div class="flex mb-8">
      <Avatar
        v-if="getUserInfo.username"
        :src="`https://storage.googleapis.com/cavea-avatar-service/twitch/${getUserInfo.username}/avatar.png`"
        size="large"
        class="mr-6"
        @error="profileImageFallback"
      />

      <div>
        <Title title="Welcome back, " size="small" />

        <Title :title="getUserInfo.username" size="large" class="capitalize" />
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mb-12">
      <div class="grid grid-cols-2 gap-8">
        <KPI :value="activeCampaigns" label="Active campaigns" title="Active campaigns you are part of" />

        <KPI label="Earned" :value="`€${calculateEarnings}`" title="Total money earned from campaign views" />
      </div>

      <Tile class="flex gap-4 content-center items-center p-4 text-center">
        <Icon width="30px" height="30px" icon="story" />

        <Title title="Creator page" size="medium" />

        <p>Connect your audience to all your content with just one link.</p>

        <Button
          id="button-streamer-dashboard-to-page-builder"
          kind="secondary"
          class="mt-auto"
          url="/page-builder"
          label="To page builder"
        />
      </Tile>

      <Tile class="flex gap-4 content-center items-center p-4 text-center">
        <Icon width="30px" height="30px" icon="overlay" />

        <Title title="Copy browser source" size="medium" />

        <p>Simply copy the link and add it as a browser source in your streaming software.</p>

        <div class="flex gap-4 items-center">
          <Button
            id="button-streamer-dashboard-browser-source-guide"
            kind="secondary"
            class="mt-auto"
            label="Guide"
            url="https://move.gg/8sonexzq"
            :new-window="true"
          />

          <Button
            id="button-streamer-dashboard-copy-browser-source"
            kind="primary"
            class="mt-auto"
            label="Copy"
            @clicked="copyStreamCode"
          />
        </div>
      </Tile>
    </div>

    <StreamerCampaigns :campaigns="getCampaigns" :show-available-campaigns="true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { KPI, Avatar, Title, Tile, Icon, Button } from "cavea-design-system";
import checkIfTargetsAchieved from "@/lib/campaigns/checkIfTargetsAchieved";

export default {
  name: "StreamerDashboard",

  metaInfo: {
    title: "Dashboard",
  },

  components: {
    StreamerCampaigns: () => import("@/components/streamer/StreamerCampaigns"),
    Button,
    KPI,
    Avatar,
    Title,
    Icon,
    Tile,
  },

  computed: {
    ...mapGetters(["getCampaigns", "getUserInfo"]),

    calculateEarnings() {
      let total = 0.0;

      if (this.getCampaigns) {
        const campaignsWithStats = this.getCampaigns.filter(
          (campaign) => campaign?.handles[0]?.statsId?.stats?.length > 0
        );

        for (const campaign of campaignsWithStats) {
          const views = campaign?.handles[0]?.statsId?.stats?.reduce((a, b) => a + (b.views || 0), 0);

          let cpm = campaign.info.priceRate;

          if (campaign.handles[0].priceRate) {
            cpm = campaign.handles[0].priceRate;
          }

          const earned = (views / 1000) * cpm; // (views / 1000) * cpm. Could also be written views/100, but i feel this is more readable.

          total += earned;
        }
      }

      return total.toFixed(1);
    },

    activeCampaigns() {
      let active = 0;

      if (this.getCampaigns?.length > 0) {
        for (const campaign of this.getCampaigns) {
          if (campaign?.targets === null || campaign?.targets?.length === 0) {
            active += 1;
          } else if (campaign?.handles && campaign?.handles[0]?.active === true) {
            const campaignStatus = checkIfTargetsAchieved(campaign);

            if (campaignStatus?.started === true && campaignStatus?.viewsAchieved === false) {
              active += 1;
            }
          }
        }
      }

      return active;
    },
  },

  methods: {
    copyStreamCode() {
      const tempElement = document.createElement("input");
      const overlayLink = `https://overlay.netwrk.gg/?id=${this.getUserInfo._id}`;
      tempElement.value = overlayLink;
      document.body.appendChild(tempElement);

      tempElement.select();
      document.execCommand("copy");
      document.body.removeChild(tempElement);
    },

    profileImageFallback(event) {
      event.target.src = "https://storage.googleapis.com/cavea-avatar-service/twitch/frymealiiiver/avatar.png";
    },
  },
};
</script>
